/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lzma@2.3.2/src/lzma.min.js
 * - /npm/slim-select@1.27.1/dist/slimselect.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/micromodal@0.4.10/dist/micromodal.min.js
 * - /npm/codemirror@5.65.5/lib/codemirror.min.js
 * - /npm/codemirror@5.65.5/addon/mode/loadmode.min.js
 * - /npm/codemirror@5.65.5/addon/mode/overlay.min.js
 * - /npm/codemirror@5.65.5/addon/mode/multiplex.min.js
 * - /npm/codemirror@5.65.5/addon/mode/simple.min.js
 * - /npm/codemirror@5.65.5/addon/scroll/simplescrollbars.js
 * - /npm/codemirror@5.65.5/mode/meta.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
